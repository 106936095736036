<template>
  <section class="tables">
    <div>
      <div class="d-flex">
        <span class="h4 text-secondary mr-auto my-auto">Liste des KPIs </span>

        <div class="w-50 d-flex align-items-center justify-content-end">
          <CustomAddButton
            :disabled="disabled"
            text="Affecter KPI"
            @click="addItem"
          />
        </div>
      </div>
      <div class="col-12 p-0">
        <hr class="mt-1 mb-3 bg-secondary" />
      </div>
    </div>
    <div class="row">
      <div class="col-12">
        <b-table
          :items="tableItems"
          id="table-list"
          responsive
          :fields="fdrFields"
          :per-page="perPage"
          :current-page="currentPage"
          :sort-by.sync="sortBy"
          :sort-desc.sync="sortDesc"
          :busy="loading"
          bordered
          striped
          show-empty
        >
          <!-- FOR LOADING ANIMATIONS -->
          <template #table-busy>
            <div class="text-center text-primary my-2">
              <b-spinner class="align-middle mr-2"></b-spinner>
              <strong>Chargement...</strong>
            </div>
          </template>

          <!-- SHOW IF TABLE IS EMPTY -->
          <template #empty>
            <div class="text-center text-gray">Table vide</div>
          </template>

          <template v-slot:cell(type)="data">
            <div v-if="!data.item.hasOwnProperty('editMode')">
              {{ data.value }}
            </div>
            <v-select
              v-else
              :options="['type 1', 'type 2']"
              v-model="type"
              placeholder="Type"
              appendToBody
            >
              <template #no-options> Liste vide </template>
            </v-select>
          </template>

          <template v-slot:cell(description)="data">
            <div v-if="!data.item.hasOwnProperty('editMode')">
              {{ data.value || "-" }}
            </div>
            <b-form-input
              v-else
              placeholder="Description"
              v-model="description"
            ></b-form-input>
          </template>
          <template v-slot:cell(val)="data">
            <div v-if="!data.item.hasOwnProperty('editMode')">
              {{ data.value }}
            </div>
            <b-form-input
              type="number"
              min="0"
              placeholder="Val"
              v-else
              v-model="val"
            ></b-form-input>
          </template>
          <template v-slot:cell(name)="data">
            <span
              v-if="!data.item.hasOwnProperty('editMode')"
              :to="{
                name: 'show-fdr',
                params: {
                  id: data.item.id,
                  slug: data.item.slug || 'abcde',
                },
              }"
            >
              {{ data.value }}
            </span>
            <!-- <v-select
              :options="data.item.id ? KPIS : kpisList"
              appendToBody
              label="name"
              :reduce="(kpi) => kpi['@id']"
              v-model="selectedKpi"
              :disabled="data.item.id"
              placeholder="Kpi"
              v-else
            ></v-select> -->

            <b-form-input
              v-model="name"
              placeholder="Kpi"
              v-else
            ></b-form-input>
          </template>

          <template #cell(actions)="data">
            <table-actions
              :editMode="data.item.hasOwnProperty('editMode')"
              @deleteItem="deleteItem(data.item.id)"
              @confirmItem="confirmItem(data.item)"
              @cancelItem="cancelItem(data.item, data.index)"
              @editItem="editItem(data.item)"
              :actions="disabled ? [] : ['edit', 'delete']"
            />
          </template>
        </b-table>
        <b-pagination
          v-model="currentPage"
          :total-rows="rows"
          :per-page="perPage"
          aria-controls="table-list"
          align="right"
        >
        </b-pagination>
      </div>
    </div>
  </section>
</template>

<script>
import { mapGetters } from "vuex";
import CustomAddButton from "@/components/custom/CustomAddButton";
import TableActions from "@/components/custom/TableActions";
import Swal from "sweetalert2";
export default {
  components: { CustomAddButton, TableActions },
  props: {
    kpis: Array,
    disabled: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      loading: true,
      sortBy: "",
      perPage: 5,
      currentPage: 1,
      sortDesc: false,
      sortByFormatted: true,
      filterByFormatted: true,
      sortable: true,
      selectedPatrimoine: null,
      selectedKpi: null,
      submiting: false,
      name: "",
      description: "",
      type: "",
      val: null,
      tableItems: [],
      fdrFields: [
        {
          key: "name",
          label: "KPI",
          sortable: true,
        },

        {
          key: "description",
          label: "Description",
          sortable: true,
        },
        {
          key: "type",
          label: "Type",
          sortable: true,
        },
        {
          key: "val",
          label: "Val",
          sortable: true,
        },

        {
          key: "actions",
          label: "Actions",
          sortable: false,
          thStyle: { width: "150px !important", minWidth: "150px !important" },
        },
      ],
    };
  },
  methods: {
    variant: function (progress) {
      if (progress <= 20) return "#f77f00";
      else if (progress >= 71) return "#2A8947";
      else if (progress >= 21 && progress <= 70) return "#fed713";
      return "default";
    },
    addItem() {
      if (!this.tableItems.some((item) => item.editMode)) {
        this.selectedKpi = null;
        this.tableItems.unshift({
          editMode: true,
        });
        this.currentPage = 1;
      }
    },
    editItem(item) {
      this.$store
        .dispatch("objectif/fetchObjectif", this.$route.params.id)
        .then(() => {
          this.tableItems = this.tableItems.map((kpi) =>
            kpi.id === item.id ? { ...kpi, editMode: true } : kpi
          );
          this.selectedPatrimoine = item.patrimoine
            ? item.patrimoine["@id"]
            : null;
          this.selectedKpi = item["@id"];
          this.name = item.name;
          this.description = item.description;
          this.type = item.type;
          this.val = item.val;
        });
    },
    cancelItem(item) {
      this.selectedPatrimoine = null;
      if (item.id) {
        if (
          this.name === "" &&
          this.description === "" &&
          this.type === "" &&
          this.val === null
        )
          this.$store.dispatch("objectif/fetchObjectif", this.$route.params.id);
        else {
          this.name = "";
          this.description = "";
          this.type = "";
          this.val = null;
        }
      } else {
        if (this.selectedKpi !== null) this.selectedKpi = null;
        else
          this.$store.dispatch("objectif/fetchObjectif", this.$route.params.id);
      }
    },
    confirmItem(item) {
      if (
        !this.submiting &&
        this.description !== "" &&
        this.type !== "" &&
        this.val !== null
      ) {
        this.submiting = true;
        if (item.id) {
          this.$store
            .dispatch("kpi/updateKpi", {
              id: item.id,
              name: this.name,
              description: this.description,
              type: this.type,
              val: parseInt(this.val),
            })
            .then(() => {
              this.$store;
              this.$store
                .dispatch("objectif/fetchObjectif", this.$route.params.id)
                .then(() => {
                  Swal.fire("Le KPI est mis à jour !", "", "success");
                  this.submiting = false;
                  this.name = this.description = this.type = "";
                  this.val = null;
                });
            });
        } else {
          this.$store
            .dispatch("kpi/createKpi", {
              objectif: `/api/strategy/objectifs/${this.$route.params.id}`,
              name: this.name,
              description: this.description,
              type: this.type,
              val: parseInt(this.val),
            })
            .then(() => {
              this.$store
                .dispatch("objectif/fetchObjectif", this.$route.params.id)
                .then(() => {
                  Swal.fire("Le KPI est bien créé !", "", "success");
                  this.submiting = false;
                  this.name = this.description = this.type = "";
                  this.val = null;
                });
            });
        }
      }
    },
    deleteItem(id) {
      Swal.fire({
        title: "Etes-vous sûr(e) ?",
        type: "warning",
        showCancelButton: true,
        confirmButtonColor: "#3085d6",
        cancelButtonColor: "#d33",
        cancelButtonText: "Annuler",
        confirmButtonText: "Confirmer",
      }).then((result) => {
        if (result.value) {
          this.$store.dispatch("kpi/deleteKpi", id).then(() => {
            this.$store
              .dispatch("objectif/fetchObjectif", this.$route.params.id)
              .then(() => {
                Swal.fire("Supprimé!", "", "success");
              });
          });
        }
      });
    },
  },
  created() {
    this.$store.dispatch("patrimoine/fetchAllPatrimoines");
    this.$store.dispatch("kpi/fetchAllKpis");
  },
  computed: {
    ...mapGetters("patrimoine", ["PATRIMOINES"]),
    ...mapGetters("kpi", ["KPIS"]),
    rows() {
      return this.tableItems ? this.tableItems.length : 0;
    },
    patrimoinesList() {
      return this.PATRIMOINES.map((patrimoine) => ({
        text: patrimoine.name,
        value: patrimoine["@id"],
      }));
    },
    kpisList() {
      return this.KPIS.filter(
        (kpi) => !this.kpis.find((existedKpi) => existedKpi.id === kpi.id)
      );
    },
  },
  mounted() {
    (this.tableItems = this.kpis), (this.loading = false);
  },
  watch: {
    kpis() {
      (this.tableItems = this.kpis), (this.loading = false);
    },
    selectedKpi() {
      // !    var kpi;
      // if (this.selectedKpi) {
      // kpi = this.kpisList.find((kpi) => kpi["@id"] === this.selectedKpi);
      //   this.description = kpi.description;
      //   this.type = kpi.type;
      //   this.val = kpi.val;
      // } else {
      //   this.description = this.type = this.val = "";
      // }
    },
  },
};
</script>
