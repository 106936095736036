<template>
  <section class="tables">
    <div>
      <div class="d-flex">
        <span class="h4 text-secondary mr-1 my-auto"
          >Liste des feuilles de route
        </span>
        <DropDownCustom
          :items="['Tableau', 'Kanban', 'Gantt']"
          class="my-auto"
          @changeValue="onValueChange"
        />
        <div class="my-auto ml-2">
          <b-dropdown
            v-if="selected == 2"
            id="dropdown-1"
            :text="selectedView.text"
            :class="`custom-drop m-md-2 btn-outline-info ${selectedView.color} `"
          >
            <b-dropdown-item
              class="col-sm-6 col-md-auto m-0 p-0"
              v-for="(view, index) in ganttView"
              :key="index"
            >
              <button
                @click="changeView(index)"
                type="button"
                :class="`btn ${view.color} btn-fw col-sm-12`"
              >
                {{ view.text }}
              </button>
            </b-dropdown-item>
          </b-dropdown>
        </div>

        <div class="w-50 d-flex align-items-center ml-auto justify-content-end">
          <CustomAddButton
            :disabled="disabled"
            text="Affecter une feuille de route"
            @click="addItem"
          />
        </div>
      </div>
      <div class="col-12 p-0">
        <hr class="mt-1 mb-3 bg-secondary" />
      </div>
    </div>
    <div v-show="selected == 0" class="row">
      <div class="col-12">
        <b-table
          :items="tableItems"
          id="table-list"
          responsive
          :fields="fdrFields"
          :per-page="perPage"
          :current-page="currentPage"
          :sort-by.sync="sortBy"
          :sort-desc.sync="sortDesc"
          :busy="loading"
          bordered
          striped
          show-empty
        >
          <!-- FOR LOADING ANIMATIONS -->
          <template #table-busy>
            <div class="text-center text-primary my-2">
              <b-spinner class="align-middle mr-2"></b-spinner>
              <strong>Chargement...</strong>
            </div>
          </template>
          <!-- SHOW IF TABLE IS EMPTY -->
          <template #empty>
            <div class="text-center text-gray">Table vide</div>
          </template>
          <template v-slot:cell(avancement)="data">
            <b-progress
              max="100"
              show-progress
              height="15px"
              style="background-color: #cacccd !important"
            >
              <b-progress-bar
                :style="{ backgroundColor: variant(data.value) }"
                :value="data.value"
                :label="`${data.value}%`"
              ></b-progress-bar>
            </b-progress>
          </template>

          <template v-slot:cell(type)="data">
            <div v-if="!data.item.hasOwnProperty('editMode')">
              {{ data.value }}
            </div>
            <b-form-input
              disabled
              placeholder="type"
              v-else
              v-model="type"
            ></b-form-input>
          </template>
          <template v-slot:cell(name)="data">
            <b-link
              v-if="!data.item.hasOwnProperty('editMode')"
              :to="{
                name: 'show-fdr',
                params: {
                  id: data.item.id,
                  slug: data.item.slug || 'abcde',
                },
              }"
            >
              {{ data.value }}
            </b-link>
            <v-select
              appendToBody
              v-else
              v-model="selectedFdr"
              :options="fdrsList"
              label="text"
              placeholder="Feuille de route"
              :reduce="(fdr) => fdr.value"
            >
              <template #no-options> Liste vide </template>
            </v-select>
          </template>
          <template v-slot:cell(status)="data">
            <label v-if="!data.item.hasOwnProperty('editMode')">{{
              data.value
            }}</label>
            <b-form-input
              disabled
              placeholder="Statut"
              v-else
              v-model="status"
            ></b-form-input>
          </template>
          <template #cell(actions)="data">
            <table-actions
              :editMode="data.item.hasOwnProperty('editMode')"
              @deleteItem="deleteItem(data.item.id)"
              @confirmItem="confirmItem(data.index, data.item)"
              @cancelItem="cancelItem(data.item, data.index)"
              :actions="disabled ? [] : ['delete']"
            />
          </template>
        </b-table>
        <b-pagination
          v-model="currentPage"
          :total-rows="rows"
          :per-page="perPage"
          aria-controls="table-list"
          align="right"
        >
        </b-pagination>
      </div>
    </div>
    <div v-show="selected == 1" class="board-wrapper pt-5">
      <fdr-kanban :items="tableItems" />
    </div>
    <div v-if="selected == 2" class="card p-3 mt-4">
      <Gantt
        :items="tableItems"
        :loadingData="loading"
        :mode="mode"
        @showInitiatives="showInitiativesFunction"
      />
    </div>
    <div to="modals-xyz-548" v-if="showInitiatives">
      <modal @close="showInitiatives = !showInitiatives">
        <initiative-list :id="selectedfdr" />
      </modal>
    </div>
  </section>
</template>

<script>
import { mapGetters } from "vuex";
import CustomAddButton from "@/components/custom/CustomAddButton";
import TableActions from "@/components/custom/TableActions";
import Swal from "sweetalert2";
import DropDownCustom from "@/components/custom/DropDown.vue";
import fdrKanban from "@/pages/transformation/fdr/components/fdrKanban";
import Gantt from "@/pages/transformation/fdr/components/Gantt.vue";
import InitiativeList from "@/pages/transformation/fdr/components/InitiativeList.vue";
import Modal from "@/components/custom/Modal";

export default {
  components: {
    CustomAddButton,
    TableActions,
    DropDownCustom,
    fdrKanban,
    Gantt,
    InitiativeList,
    Modal,
  },
  props: {
    objectif: Object,
    disabled: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      loading: true,
      sortBy: "name",
      perPage: 5,
      //
      selectedFdr: null,
      type: "",
      status: "",
      avancement: 0,
      //
      currentPage: 1,
      sortDesc: false,
      sortByFormatted: true,
      filterByFormatted: true,
      sortable: true,
      tableItems: [],
      fdrFields: [
        {
          key: "name",
          label: "FDR",
          sortable: true,
        },
        {
          key: "type",
          label: "Type",
          sortable: true,
        },
        {
          key: "status",
          label: "Statut",
          sortable: true,
        },
        {
          key: "avancement",
          label: "Avancement",
          sortable: true,
          thStyle: { textAlign: "center !important" },
        },
        {
          key: "actions",
          label: "Actions",
          sortable: false,
          thStyle: { width: "150px !important", minWidth: "150px !important" },
        },
      ],
      //
      selected: 0,
      ganttView: [
        {
          text: "Jour",
          id: 1,
          color: " btn-inverse-primary",
        },
        {
          text: "Semaine",
          id: 2,
          color: "btn-inverse-danger ",
        },
        {
          text: "Mois",
          id: 3,
          color: "btn-inverse-info ",
        },
        {
          text: "Année",
          id: 4,
          color: "btn-inverse-secondary ",
        },
      ],
      selectedView: {
        text: "Jour",
        id: 1,
        color: " btn-inverse-primary",
      },
      selectedfdr: null,
      showInitiatives: false,
      mode: "day",
    };
  },
  methods: {
    variant: function (progress) {
      if (progress <= 20) return "#f77f00";
      else if (progress >= 71) return "#2A8947";
      else if (progress >= 21 && progress <= 70) return "#fed713";
      return "default";
    },
    addItem() {
      if (
        this.isAdmin ||
        this.isSuper ||
        this.isManager ||
        this.isArchitect ||
        this.isPo
      )
        if (!this.tableItems.some((item) => item.editMode)) {
          this.tableItems.unshift({
            editMode: true,
          });
          this.currentPage = 1;
          this.selected = 0;
        }
    },
    cancelItem() {
      if (this.selectedFdr) {
        this.selectedFdr = null;
      } else
        this.$store.dispatch(
          "objectif/fetchFdrsObjectif",
          this.$route.params.id
        );
    },
    confirmItem() {
      if (this.selectedFdr) {
        var fdrs = this.tableItems
          .map((item) => item["@id"])
          .filter((item) => item);
        fdrs.push(this.selectedFdr);
        this.$store
          .dispatch("objectif/updateObjectif", {
            id: this.$route.params.id,
            feuilleRoutes: fdrs,
          })
          .then(() => {
            this.$store;
            this.$store
              .dispatch("objectif/fetchFdrsObjectif", this.$route.params.id)
              .then(() => {
                Swal.fire("La FdR a été affectée !", "", "success");
                this.selectedFdr = null;
              });
          });
      }
    },
    deleteItem(id) {
      Swal.fire({
        title: "Etes-vous sûr(e) ?",
        type: "warning",
        showCancelButton: true,
        confirmButtonColor: "#3085d6",
        cancelButtonColor: "#d33",
        cancelButtonText: "Annuler",
        confirmButtonText: "Confirmer",
      }).then((result) => {
        if (result.value) {
          var fdrs = this.tableItems
            .filter((item) => item.id != id)
            .map((item) => item["@id"]);
          this.$store
            .dispatch("objectif/updateObjectif", {
              id: this.$route.params.id,
              feuilleRoutes: fdrs,
            })
            .then(() => {
              this.$store;
              this.$store
                .dispatch("objectif/fetchFdrsObjectif", this.$route.params.id)
                .then(() => {
                  Swal.fire("Supprimé!", "", "success");
                });
            });
        }
      });
    },
    onValueChange: function (e) {
      this.selected = e;
    },
    changeView: function (val) {
      this.mode =
        val == 0 ? "day" : val == 1 ? "week" : val == 2 ? "month" : "year";
      this.selectedView = this.ganttView[val];
    },
    showInitiativesFunction(id) {
      this.selectedfdr = id;
      this.showInitiatives = !this.showInitiatives;
    },
  },
  created() {
    if (this.$route.params.id)
      this.$store
        .dispatch("objectif/fetchFdrsObjectif", this.$route.params.id)
        .then(() => {
          this.loading = false;
        });
    else {
      this.loading = false;
    }
    this.$store.dispatch("fdr/fetchAllFdrs");
  },
  computed: {
    ...mapGetters("objectif", ["FDRS"]),
    ...mapGetters("fdr", {
      feuilleRoutes: "FDRS",
    }),
    rows() {
      return this.tableItems.length;
    },
    fdrsList() {
      return this.feuilleRoutes
        .map((feuille) => ({
          text: feuille.name,
          value: feuille["@id"],
        }))
        .filter(
          (feuille) =>
            !this.tableItems.find((fdr) => fdr["@id"] === feuille.value)
        );
    },
  },
  watch: {
    FDRS() {
      if (this.$route.params.id) this.tableItems = this.FDRS.feuilleRoutes;
    },
    selectedFdr() {
      if (this.selectedFdr) {
        var feuille = this.feuilleRoutes.find(
          (feuille) => feuille["@id"] === this.selectedFdr
        );
        this.type = feuille.type;
        this.status = feuille.status;
        this.avancement = feuille.avancement;
      } else {
        this.type = "";
        this.status = "";
        this.avancement = 0;
      }
    },
  },
};
</script>

<style>
#dropdown-1__BV_toggle_ {
  background: #38b000 !important;
  border-color: #38b000 !important;
  width: 140px !important;
}
.custom-drop .dropdown-item,
.custom-drop .dropdown-menu {
  padding: 0 !important;
}
</style>
